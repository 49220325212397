import './blobs.scss'

import { useLocation } from '@reach/router'
import { Arrays } from '@rocketmakers/armstrong-edge'
import * as React from 'react'

import { MathsUtils } from '../utils/maths'

interface IBlob {
  size: number
  x: number
  y: number
  colourOption: number
}

interface IBlobsProps {}

export const Blobs: React.FC<IBlobsProps> = () => {
  const { pathname } = useLocation()

  const blobs = React.useMemo<IBlob[]>(
    () =>
      Arrays.repeat<IBlob>(4, () => {
        return {
          size: MathsUtils.randomBetween(400, 1400),
          x: MathsUtils.randomBetween(-10, 110),
          y: MathsUtils.randomBetween(-10, 110),
          colourOption: MathsUtils.randomIntegerBetween(1, 3),
        }
      }),
    [pathname]
  )

  return (
    <div className="blobs" key={pathname}>
      {blobs.map((blob, index) => (
        <div
          className="blob-wrapper"
          key={index}
          data-colour-option={blob.colourOption}
          style={
            {
              '--x': `${blob.x}%`,
              '--y': `${blob.y}%`,
            } as React.CSSProperties
          }
        >
          <div
            className="blob"
            style={
              {
                '--size': `${blob.size}px`,
              } as React.CSSProperties
            }
          />
        </div>
      ))}
    </div>
  )
}
