import './gutter.scss'

// import { Arrays } from '@rocketmakers/armstrong-edge'
import * as React from 'react'

import { Assets } from '../content/assets'
// import { MathsUtils } from '../utils/maths'

interface IGutterSnogProps {
  width: number
}

export const GutterSnog: React.FC<IGutterSnogProps> = ({ width }) => {
  return (
    <div className="gutter-snog" aria-hidden style={{ '--width': `${width}px` } as React.CSSProperties}>
      <img alt="" src={Assets.logoMarkLightSmall} />
    </div>
  )
}

interface GutterSnogSectionProps {
  widths: number[]
}

export const GutterSnogSection: React.FC<GutterSnogSectionProps> = ({ widths }) => {
  return (
    <div className="gutter-snog-section" aria-hidden>
      {widths.map((width, index) => (
        <GutterSnog key={`${width}_${index}`} width={width} />
      ))}
    </div>
  )
}

interface IGutterProps {
  side: 'left' | 'right'
}

export const Gutter: React.FC<IGutterProps> = ({ side }) => {
  // const gutterSnogWidths = React.useMemo(() => Arrays.repeat(30, () => MathsUtils.randomBetween(150, 150)), [])

  return (
    <div className="gutter" data-side={side}>
      {/* <GutterSnogSection widths={gutterSnogWidths} />
      <GutterSnogSection widths={gutterSnogWidths} /> */}
    </div>
  )
}

Gutter.defaultProps = {
  side: 'left',
}
