import './shell.scss'

import { useLocation } from '@reach/router'
import { useDidUpdateEffect } from '@rocketmakers/armstrong-edge'
import * as React from 'react'

import { useDisableScroll } from '../hooks/useDisableScroll'
import { PlatformUtils } from '../utils/platform'
import { Routes } from '../utils/routes'
import { Blobs } from './blobs'
import { Gutter } from './gutter'
import { Header } from './header'
import { Meta } from './meta'
import { Nav } from './nav'
import { SnogLogoLazy } from './snogLogoLazy'

export interface IShellProps {
  isHome?: boolean
}

export const Shell: React.FC<React.PropsWithChildren<IShellProps>> = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = React.useState(false)

  useDisableScroll(isNavOpen)

  const { pathname } = useLocation()

  useDidUpdateEffect(() => {
    setIsNavOpen(false)
  }, [pathname])

  const ref = React.useRef<HTMLDivElement>(null)

  const [isSafari, setIsSafari] = React.useState<boolean>(PlatformUtils.isSafari())

  // really weird and gross fix for safari for some fucking reason
  React.useLayoutEffect(() => {
    setIsSafari(PlatformUtils.isSafari())
    if (ref.current) {
      // last ditch effort because the state wasn't working
      ref.current.setAttribute('data-is-safari', PlatformUtils.isSafari() ? 'true' : 'false')
    }
    setTimeout(() => {
      setIsSafari(PlatformUtils.isSafari())
      // last ditch effort because the state wasn't working
      if (ref.current) {
        ref.current.setAttribute('data-is-safari', PlatformUtils.isSafari() ? 'true' : 'false')
      }
    }, 10)
  }, [])

  return (
    <div ref={ref} className="shell" data-is-nav-open={isNavOpen} data-home={pathname === Routes.home} data-is-safari={isSafari}>
      <Meta />

      <div className="shell-content" onClick={() => isNavOpen && setIsNavOpen(false)} tabIndex={isNavOpen ? -1 : undefined} aria-hidden={isNavOpen}>
        {children}
      </div>

      <Header isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />

      <Blobs />

      <Gutter side="left" />
      <Gutter side="right" />

      <Nav isNavOpen={isNavOpen} />

      {pathname !== Routes.home && (
        <div className="header-logo">
          <SnogLogoLazy pointMultiplier={10} />
        </div>
      )}
    </div>
  )
}
