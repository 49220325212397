import { graphql, useStaticQuery } from 'gatsby'

export const useNavQuery = () => {
  const data = useStaticQuery<Queries.UseNavQuery>(
    graphql`
      query UseNav {
        prismicNavigation {
          data {
            links {
              text
              link {
                url
              }
            }
          }
        }
      }
    `
  )

  return data.prismicNavigation?.data
}
