module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"snog-website","accessToken":"MC5ZeTJRRlJFQUFDTUFncVlZ.awzvv73vv71l77-977-977-977-9HTh377-9dVnvv70O77-977-9DTbvv73vv71n77-9DO-_ve-_vSM477-977-9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Snog","short_name":"Snog","start_url":"/","background_color":"#E3E3E3","theme_color":"#AA00FF","display":"standalone","icon":"./src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e6d384456f9b25eca16c363c8b8fbe2b"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
