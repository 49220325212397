export namespace Routes {
  export const home = '/'
  export const about = '/about'
  export const shows = '/shows'
  export const show = (uid: string) => `/shows/show/${uid}`
  export const showsPast = '/shows/past'
  export const management = '/roster'
  export const artist = (uid: string) => `/roster/artist/${uid}`
  export const contact = '/contact'
}
