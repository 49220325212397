import logo from '../assets/snog-logo.png'
import logoMark from '../assets/snog-logo-mark.png'
import logoMarkLight from '../assets/snog-logo-mark-light.png'
import logoMarkLightSmall from '../assets/snog-logo-mark-light-small.png'
import logoMini from '../assets/snog-logo-mini.png'

export const Assets = {
  logo,
  logoMini,
  logo3d: '/Snog_small.gltf',
  logoMark,
  logoMarkLight,
  logoMarkLightSmall,
}
