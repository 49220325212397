export namespace PlatformUtils {
  export const isSafari = () =>
    typeof window !== 'undefined' &&
    typeof navigator !== 'undefined' &&
    (!!(window as any).safari ||
      (!!navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        !!navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1))

  // uncomment to test safari
  //   export const isSafari = true
}

if (typeof window !== 'undefined') (window as any).isSafari = PlatformUtils.isSafari
