import { useHasTimeElapsedSinceMount } from '@rocketmakers/armstrong-edge'
import * as React from 'react'

import { ISnogLogoProps } from './snogLogo'

const SnogLogo = React.lazy(() => import('./snogLogo'))

interface ISnogLogoLazyProps extends ISnogLogoProps {}

export const SnogLogoLazy: React.FC<ISnogLogoLazyProps> = (props) => {
  const shouldRender = useHasTimeElapsedSinceMount(500)

  if (!shouldRender) {
    return null
  }

  return (
    <React.Suspense>
      <SnogLogo {...props} />
    </React.Suspense>
  )
}
