import { useLocation } from '@reach/router'
import { ArmstrongConfigProvider, ModalProvider, ToastProvider } from '@rocketmakers/armstrong-edge'
import { GatsbyBrowser, navigate } from 'gatsby'
// import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import * as React from 'react'

import { linkResolver } from '../gatsby/linkResolver'
import { LinkWrapper } from './components/linkWrapper'
import { Shell } from './components/shell'
import { MousePositionProvider } from './hooks/useMousePosition'

export const Providers: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation()

  return (
    <ArmstrongConfigProvider
      routing={{ LinkComponent: LinkWrapper, location, navigate: (to, action) => navigate(to, { replace: action === 'replace' }) }}
    >
      {/* <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: 'snog-website',
            linkResolver,
            componentResolver: {
              home_page: React.lazy(() => import('./pages/index')),
              metadata: React.lazy(() => import('./pages/index')),
            } as any,
          },
        ]}
      > */}
      <ModalProvider>
        <ToastProvider autoDismissTime={6000}>
          <MousePositionProvider>
            <Shell>{children}</Shell>
          </MousePositionProvider>
        </ToastProvider>
      </ModalProvider>
      {/* </PrismicPreviewProvider> */}
    </ArmstrongConfigProvider>
  )
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return <Providers>{element}</Providers>
}
