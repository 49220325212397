import './header.scss'

import { useLocation } from '@reach/router'
import { Button } from '@rocketmakers/armstrong-edge'
import { Link } from 'gatsby'
import * as React from 'react'

import { useNavQuery } from '../hooks/useNavQuery'
import { Routes } from '../utils/routes'

interface IHeaderLinkProps {
  text?: string
  url?: string
}

export const HeaderLink: React.FC<IHeaderLinkProps> = ({ text, url }) => {
  return (
    <Link activeClassName="active" className="header-link" to={url || ''}>
      {text}
    </Link>
  )
}

interface IHeaderProps {
  isNavOpen: boolean
  setIsNavOpen: (newisNavOpen: boolean) => void
}

export const Header: React.FC<IHeaderProps> = ({ isNavOpen, setIsNavOpen }) => {
  const data = useNavQuery()

  const { pathname } = useLocation()

  return (
    <header className="header" data-home={pathname === Routes.home} data-is-nav-open={isNavOpen}>
      <Link to={Routes.home} className="header-logo-space" aria-label="home"></Link>

      {pathname !== Routes.home &&
        data?.links?.map((link) => (
          <React.Fragment key={link?.text}>
            {/* {index > 0 && <div className="header-link-divider" />} */}
            <div className="header-link-divider" />
            <HeaderLink text={link?.text || ''} url={link?.link?.url || ''} />
          </React.Fragment>
        ))}

      <Button
        className="header-menu-link no-outline"
        onClick={() => setIsNavOpen(!isNavOpen)}
        data-is-nav-open={isNavOpen}
        aria-label={isNavOpen ? 'close menu' : 'open menu'}
        aria-haspopup="menu"
        aria-controls="sg-menu"
        aria-expanded={isNavOpen}
      >
        <div className="header-menu-link-inner" />
      </Button>
    </header>
  )
}
