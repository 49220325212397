import './nav.scss'

import { useDelayedDependentSwitch } from '@rocketmakers/armstrong-edge'
import { Link } from 'gatsby'
import * as React from 'react'

import { useNavQuery } from '../hooks/useNavQuery'

interface INavProps {
  isNavOpen: boolean
}

export const Nav: React.FC<INavProps> = ({ isNavOpen }) => {
  const data = useNavQuery()

  const [isOpen, isClosing] = useDelayedDependentSwitch(isNavOpen, 500)

  if (!isOpen && !isClosing) {
    return null
  }

  return (
    <nav className="nav" id="sg-menu" role="menu" data-open={isOpen} data-closing={isClosing}>
      {data?.links?.map((link, index) => (
        <React.Fragment key={index}>
          {/* {index !== 0 ? <span>/</span> : <div />} */}
          <Link activeClassName="active" className="nav-link" to={link?.link?.url || ''}>
            <span>{link?.text}</span>
          </Link>
        </React.Fragment>
      ))}
    </nav>
  )
}
